import React from 'react';
import TrackVisibility from 'react-on-screen';
import {OutboundLink} from 'gatsby-plugin-google-analytics';

import {
    Graphic,
    Image,
    Layout,
    Nav,
} from 'components';

import {
    chris,
    chris2x,
    james,
    james2x,
    map,
    map2x,
} from 'assets';


const IndexPage = () => {
    return (
        <Layout>
            <Nav/>

            <header>
                <h1>
                    {'Meticulous design '}
                    <span>{'&'}</span>
                    {' development for the web.'}
                </h1>
                <h2>
                    <em>{'Fundamental'}</em>
                    {' is a couple of mates who design and build things.'}
                </h2>
            </header>

            <section className="workshop">
                <article>
                    <h3>{'What we do'}</h3>
                    <p>
                        {'Software and design with a focus on '}
                        <strong>performance</strong>
                        {' and '}
                        <strong>accessibility.</strong>
                        {' Chris and James have extensive backgrounds in web development, product design and branding. Questions? '}
                        <OutboundLink href="mailto:hello@fundamental.shop">
                            {'Say hello'}
                            <Graphic name="arrow"/>
                        </OutboundLink>
                    </p>
                </article>
                <section className="tiles">
                    <h3>{'In our Workshop'}</h3>
                    <OutboundLink href="https://github.com/fundamentalshop/pigmentjs">
                        <Graphic name="pigment"/>
                        <strong>{'PigmentJS'}</strong>
                        {'Open-source JS library for working with colour'}
                    </OutboundLink>
                    <div className="ally" title="Currently in development">
                        <Graphic name="ally"/>
                        <strong>{'Ally'}</strong>
                        {'Automated accessibility testing and linting'}
                    </div>
                </section>
            </section>

            <section className="tiles shipped">
                <h3>{'Shipped'}</h3>
                <OutboundLink href="https://au.betterconsult.com/">
                    <Graphic name="betterconsult"/>
                    <strong>{'BetterConsult'}</strong>
                    {'by HealthShare'}
                </OutboundLink>
                <OutboundLink href="https://apps.apple.com/au/app/weather-by-willyweather/id592978502">
                    <Graphic name="appStore"/>
                    <strong>{'WillyWeather'}</strong>
                    {'for iOS'}
                </OutboundLink>
                <OutboundLink href="https://www.willyweather.com.au/nsw/sydney/sydney.html">
                    <Graphic name="willyweather"/>
                    <strong>{'WillyWeather'}</strong>
                    {'Website'}
                </OutboundLink>
                <OutboundLink href="https://www.willyweather.com.au/info/widget/warning.html">
                    <Graphic name="willyweatherWidgets"/>
                    <strong>{'WillyWeather'}</strong>
                    {'Widgets'}
                </OutboundLink>
            </section>

            <div className="authors">
                <TrackVisibility once>
                    {({isVisible}) =>
                        <figure className={`map${isVisible ? ' visible' : ''}`}>
                            <Image src={map} src2x={map2x}/>
                            <Graphic
                                name="mapPin"
                                style={{
                                    left: '29.70238095%',
                                    top: '6.842105263%',
                                }}
                            />
                            <Graphic
                                name="mapPin"
                                style={{
                                    left: '39.28571429%',
                                    top: '55.96491228%',
                                }}
                            />
                            <Graphic
                                name="mapPin"
                                style={{
                                    left: '70.17857143%',
                                    top: '94.03508772%',
                                }}
                            />
                        </figure>
                    }
                </TrackVisibility>
                <ul>
                    <li>
                        <Image src={james} src2x={james2x}/>
                        <strong>
                            {'James Toohey'}
                            <OutboundLink
                                href="http://github.com/jamestoohey"
                                title="James on GitHub"
                            >
                                <Graphic name="github"/>
                            </OutboundLink>
                            <OutboundLink
                                href="https://toohey.io/"
                                title="James on the Web"
                            >
                                <Graphic name="website"/>
                            </OutboundLink>
                        </strong>
                        <address>{'Cairns & Sydney, Australia'}</address>
                        <p>{'A developer with a knack for UX'}</p>
                    </li>
                    <li>
                        <Image src={chris} src2x={chris2x}/>
                        <strong>
                            {'Chris Sealey'}
                            <OutboundLink
                                href="http://github.com/chrissealey"
                                title="Chris on GitHub"
                            >
                                <Graphic name="github"/>
                            </OutboundLink>
                            <OutboundLink
                                href="http://chrissealey.me/"
                                title="Chris on the Web"
                            >
                                <Graphic name="website"/>
                            </OutboundLink>
                        </strong>
                        <address>{'Queenstown, New Zealand'}</address>
                        <p>{'A designer who can actually code'}</p>
                    </li>
                </ul>
            </div>
        </Layout>
    );
};

export default IndexPage;
